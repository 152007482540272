import React, { useEffect, useState } from "react";
import axios from "axios";


/**
 * 
 * https://github.com/andrewevans0102/weather-text
 * https://blog.alexandrudanpop.dev/posts/correctly-handling-async-await-in-react-components-4h74/
 * https://tushar-balar-27618.medium.com/how-to-use-async-await-in-the-functional-component-react-js-15d0fa9137d3
 * 
 */
/*
function walk( o , recurse=false) {
 console.log("\noo", o);
 console.log("\t", typeof o);
 for (const [key, value] of Object.entries(o)) {
   console.log(`>> ${key}: ${value}`);
   if( recurse && "object" == typeof value) {
     walk(value , recurse);
   }
 }
}
*/

// Applies the following format to date: WeekDay, Month Day, Year
function getFormattedDate(date){
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date * 1000).toLocaleDateString("en-US",options);
}

/**
 * Helper method that formats number returned from OpenWeatherMapAPI into formatted timestamp for message
 * @param  {number} time, time in number form from OpenWeatherMapAPI
 * @return {string} formatted time
 */
//  function formatTime(time) {
//   const zeroTime = new Date(0);
//   zeroTime.setUTCSeconds(time);
//   let minutes = String(zeroTime.getUTCMinutes());
//   if (minutes.length < 2) {
//     minutes = "0" + minutes;
//   }
//   // formatted for eastern standard time
//   let hours = String(zeroTime.getUTCHours() - 4);
//   if (hours > 12) {
//     hours = hours - 12;
//   }
//   if (hours.length < 2) {
//     hours = "0" + hours;
//   }
//   return hours + ":" + minutes;
// }

/**
 * helper method that retrieves the hours for the current day from the NOAA API hourly forecast
 * @param  {[]} periods, array of hourly periods from NOAA API
 * @return {[]} array of hourly periods specific to today
 */
// function retrieveHours(periods) {
//   const today = new Date();
//   // get month starts counting at 0 so 8 = september
//   const monthNumber = parseInt(today.getMonth()) + 1;
//   let monthString = monthNumber.toString();
//   if (monthString.length === 1) {
//     monthString = "0" + monthString;
//   }
//   // get day of month 1-31 here
//   let dayString = today.getDate().toString();
//   if (dayString.length === 1) {
//     dayString = "0" + dayString;
//   }
//   const timePrefix = today.getFullYear() + "-" + monthString + "-" + dayString;
//   return periods.filter(
//     period => period.startTime.toString().includes(timePrefix) === true
//   );
// }

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export default function Weather({ more, loadMore }) {
  const [loading, setLoading] = useState(true);
  const [weatherReport, setWeatherReport] = useState({});



  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      const id = "shrink"; // "shrink";
      const openWeatherMapAPIURL = `https://1qqnpu5gj0.execute-api.us-east-2.amazonaws.com/Prod/getweather/${id}`;
      try {

        setLoading(true);
        const currentWeather = await axios(
          openWeatherMapAPIURL,
          {
            responseType: 'json',
            signal: controller.signal,
          }
        );

        setWeatherReport(currentWeather);

      } catch (err) {
        console.log("catch");
        if (axios.isCancel(err)) {
          return console.info(err);
        }

        console.error(err);
      } finally {
        setLoading(false);
      }

    };
  
    fetchData().catch(console.error);

    return () => {
      console.log("Calling cleanup");

      // here we cancel preveous http request that did not complete yet
      controller.abort()
    };      
  }, []);

  if (loading || !weatherReport.data) {
  // if (loading) {
      return <div>Loading...</div>;
  }

  // console.log("weatherReport", weatherReport);

/**
 *         const sunrise = formatTime(currentWeather.data.sys.sunrise);
        const sunset = formatTime(currentWeather.data.sys.sunset);
        // const message =
        //   "WEATHER TEXT:\n" +
        //   "\n" +
        //   "Good Morning!  ☀️ 💦 🌤 ⛈ \n" +
        //   "Here's the lowdown for today...\n" +
        //   "\n" +
        //   // to show degree symbol on OSX hold shift + option + 8
        //   `temp: ${currentWeather.data.main.temp.toFixed(0)}°\n` +
        //   `high: ${highTemp.toString()}°\n` +
        //   `low: ${lowTemp.toString()}°\n` +
        //   `wind: ${currentWeather.data.wind.speed.toFixed(0)} MPH\n` +
        //   `sunrise: ${sunrise} AM\n` +
        //   `sunset: ${sunset} PM\n` +
        //   "\n" +
        //   `forecast: ${NOAAWeeklyForecast.data.properties.periods[0].detailedForecast}\n` +
        //   "\n" +
        //   "Have a good day! 🎉🎉 🎉 🎉";        
          const message =
          "WEATHER TEXT:\n" +
          "\n" +
          "Good Morning!  ☀️ 💦 🌤 ⛈ \n" +
          "Here's the lowdown for today...\n" +
          "\n" +
          // to show degree symbol on OSX hold shift + option + 8
          `temp: ${currentWeather.data.main.temp.toFixed(0)}°\n` +
          `wind: ${currentWeather.data.wind.speed.toFixed(0)} MPH\n` +
          `sunrise: ${sunrise} AM\n` +
          `sunset: ${sunset} PM\n` +
          "\n" +
          "Have a good day! 🎉🎉 🎉 🎉";        

        // setWeatherReport(message);
 */


const weatherIconsMap = {
  "01d": "wi-day-sunny",
  "01n": "wi-night-clear",
  "02d": "wi-day-cloudy",
  "02n": "wi-night-cloudy",
  "03d": "wi-cloud",
  "03n": "wi-cloud",
  "04d": "wi-cloudy",
  "04n": "wi-cloudy",
  "09d": "wi-showers",
  "09n": "wi-showers",
  "10d": "wi-day-hail",
  "10n": "wi-night-hail",
  "11d": "wi-thunderstorm",
  "11n": "wi-thunderstorm",
  "13d": "wi-snow",
  "13n": "wi-snow",
  "50d": "wi-fog",
  "50n": "wi-fog"
};



  const {data} = weatherReport;
  const weather = JSON.parse(data.weather);
  const {daily} = weather;


  let theFuture = [];
  for(var i=1 ; i < 5 ; i++)    {
    theFuture.push(
          <div className="col-md-3 col-sm-6 day-weather-box" key={i}>
            <div className="col-sm-12 day-weather-inner-box">
              <div className="col-sm-8 forecast-main">
                <p id={`forecast-day-${i}-name`}>{getFormattedDate(daily[i].dt).substring(0,3)}</p>
                <div className="row">
                  <h5 id={`forecast-day-${i}-main`}>{Math.round(daily[i].temp.day)}</h5>
                  <i className={`wi forecast-icon ${weatherIconsMap[daily[i].weather[0].icon]}`} id={`forecast-day-${i}-icon`}></i>
                </div>
              </div>
              <div className="col-sm-4 forecast-min-low">
                <p><span className="high-temperature" id={`forecast-day-${i}-ht`}>{Math.round(daily[i].temp.max)}</span></p>
                <p><span className="low-temperature" id={`forecast-day-${i}-lt`}>{Math.round(daily[i].temp.min)}</span></p>
              </div>
            </div>
          </div>
    );
  };

// console.log(theFuture);

  // https://codepen.io/MiguelEnc/pen/vmZVar
  return (
    <div className="container" id="wrapper">
    <div className="container-fluid" id="current-weather">
      <div className="row">

          {/* <!-- Right panel --> */}
          <div className="col-md-4 col-sm-5">
            <h5><span id="cityName">Cuyahoga Falls</span>, <span id="cityCode">US</span></h5>
            <h6 id="localDate"></h6>
            <h5 id="localTime"></h5>
          </div>
          
          {/* <!-- Center panel --> */}
          <div className="col-md-5 col-sm-7" style={{margin: "10px auto", padding:"0"}}>
            <div className="row">
              <i className={`wi ${weatherIconsMap[daily[0].weather[0].icon]}`} id ="main-icon" style={{fontSize: "85px"}}></i>
              <div>
                <span id="mainTemperature">{Math.round(daily[0].temp.day)}</span>
                <p id="tempDescription">{daily[0].weather[0].description}</p>
              </div>
              <p style={{fontSize: "1.5rem"}}>°F</p>
            </div>
          </div>
          
          {/* <!-- Left panel --> */}
          <div className="col-xs-12 col-sm-12 col-md-3 row" style={{textAlign: "right"}}>
            <div className="col-md-12 col-sm-3 col-xs-3 side-weather-info">
              <h6>Humidity: <span id="humidity">{daily[0].humidity}</span>%</h6>
            </div>
            <div className="col-md-12 col-sm-3 col-xs-3 side-weather-info">
              <h6>Wind: <span id="wind"></span>{daily[0].wind_speed} m/s</h6>
            </div>
            <div className="col-md-12 col-sm-3 col-xs-3 side-weather-info">
              <h6>High: <span id="mainTempHot">{Math.round(daily[0].temp.max)} </span>°</h6>
            </div>
            <div className="col-md-12 col-sm-3 col-xs-3 side-weather-info">
              <h6>Low: <span id="mainTempLow">{Math.round(daily[0].temp.min)} </span>°</h6>
            </div>
          </div>    

      </div>

      </div>

      {/* <!-- 4 days forecast --> */}
      <div className="container-fluid">
        <div className="row" style={{padding: "2px"}}>

        {theFuture}

        </div>
      </div>


    </div>
  );
};
 
