import React, { useEffect, useState, Component } from "react";
import axios from "axios";
import Chart from "react-apexcharts";


/**
 * 
 * https://github.com/andrewevans0102/weather-text
 * https://blog.alexandrudanpop.dev/posts/correctly-handling-async-await-in-react-components-4h74/
 * https://tushar-balar-27618.medium.com/how-to-use-async-await-in-the-functional-component-react-js-15d0fa9137d3
 * 
 */
/*
function walk( o , recurse=false) {
 console.log("\noo", o);
 console.log("\t", typeof o);
 for (const [key, value] of Object.entries(o)) {
   console.log(`>> ${key}: ${value}`);
   if( recurse && "object" == typeof value) {
     walk(value , recurse);
   }
 }
}
*/

// Applies the following format to date: WeekDay, Month Day, Year
// function getFormattedDate(date){
//   var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
//   return new Date(date * 1000).toLocaleDateString("en-US",options);
// }

/**
 * Helper method that formats number returned from OpenWeatherMapAPI into formatted timestamp for message
 * @param  {number} time, time in number form from OpenWeatherMapAPI
 * @return {string} formatted time
 */
//  function formatTime(time) {
//   const zeroTime = new Date(0);
//   zeroTime.setUTCSeconds(time);
//   let minutes = String(zeroTime.getUTCMinutes());
//   if (minutes.length < 2) {
//     minutes = "0" + minutes;
//   }
//   // formatted for eastern standard time
//   let hours = String(zeroTime.getUTCHours() - 4);
//   if (hours > 12) {
//     hours = hours - 12;
//   }
//   if (hours.length < 2) {
//     hours = "0" + hours;
//   }
//   return hours + ":" + minutes;
// }

/**
 * helper method that retrieves the hours for the current day from the NOAA API hourly forecast
 * @param  {[]} periods, array of hourly periods from NOAA API
 * @return {[]} array of hourly periods specific to today
 */
// function retrieveHours(periods) {
//   const today = new Date();
//   // get month starts counting at 0 so 8 = september
//   const monthNumber = parseInt(today.getMonth()) + 1;
//   let monthString = monthNumber.toString();
//   if (monthString.length === 1) {
//     monthString = "0" + monthString;
//   }
//   // get day of month 1-31 here
//   let dayString = today.getDate().toString();
//   if (dayString.length === 1) {
//     dayString = "0" + dayString;
//   }
//   const timePrefix = today.getFullYear() + "-" + monthString + "-" + dayString;
//   return periods.filter(
//     period => period.startTime.toString().includes(timePrefix) === true
//   );
// }

/**
 * 
 * @param {*} param0 
 * @returns 
 */
// class Pool extends Component {

//   constructor(props) {
//     super(props);

//     this.state = {
//       options: {},
//       series: [44, 55, 41, 17, 15],
//       labels: ['A', 'B', 'C', 'D', 'E']
//     };  
//   };
//   const [loading, setLoading] = useState(true);
//   const [poolReport, setPoolReport] = useState({});



  // useEffect(() => {
  //   const controller = new AbortController();

  //   const fetchData = async () => {
  //     const id = "shrink"; // "shrink"; //"w-test";
  //     const openWeatherMapAPIURL = `https://sa7wajml10.execute-api.us-east-2.amazonaws.com/Prod/${id}`;
  //     try {

  //       setLoading(true);
  //       const currentWeather = await axios(
  //         openWeatherMapAPIURL,
  //         {
  //           responseType: 'json',
  //           signal: controller.signal,
  //         }
  //       );

  //       setPoolReport(currentWeather);

  //     } catch (err) {
  //       console.log("catch");
  //       if (axios.isCancel(err)) {
  //         return console.info(err);
  //       }

  //       console.error(err);
  //     } finally {
  //       setLoading(false);
  //     }

  //   };
  
  //   fetchData().catch(console.error);

  //   return () => {
  //     console.log("Calling cleanup");

  //     // here we cancel preveous http request that did not complete yet
  //     controller.abort()
  //   };      
  // }, []);

  // if (loading || !poolReport.data) {
  // // if (loading) {
  //     return <div>Loading...</div>;
  // }

  // console.log("weatherReport", weatherReport);

/**
 *         const sunrise = formatTime(currentWeather.data.sys.sunrise);
        const sunset = formatTime(currentWeather.data.sys.sunset);
        // const message =
        //   "WEATHER TEXT:\n" +
        //   "\n" +
        //   "Good Morning!  ☀️ 💦 🌤 ⛈ \n" +
        //   "Here's the lowdown for today...\n" +
        //   "\n" +
        //   // to show degree symbol on OSX hold shift + option + 8
        //   `temp: ${currentWeather.data.main.temp.toFixed(0)}°\n` +
        //   `high: ${highTemp.toString()}°\n` +
        //   `low: ${lowTemp.toString()}°\n` +
        //   `wind: ${currentWeather.data.wind.speed.toFixed(0)} MPH\n` +
        //   `sunrise: ${sunrise} AM\n` +
        //   `sunset: ${sunset} PM\n` +
        //   "\n" +
        //   `forecast: ${NOAAWeeklyForecast.data.properties.periods[0].detailedForecast}\n` +
        //   "\n" +
        //   "Have a good day! 🎉🎉 🎉 🎉";        
          const message =
          "WEATHER TEXT:\n" +
          "\n" +
          "Good Morning!  ☀️ 💦 🌤 ⛈ \n" +
          "Here's the lowdown for today...\n" +
          "\n" +
          // to show degree symbol on OSX hold shift + option + 8
          `temp: ${currentWeather.data.main.temp.toFixed(0)}°\n` +
          `wind: ${currentWeather.data.wind.speed.toFixed(0)} MPH\n` +
          `sunrise: ${sunrise} AM\n` +
          `sunset: ${sunset} PM\n` +
          "\n" +
          "Have a good day! 🎉🎉 🎉 🎉";        

        // setWeatherReport(message);
 */

  // const {data} = poolReport;
  // const weather = JSON.parse(data.weather);
  // const {daily} = weather;




// console.log(theFuture);
// https://frappe.io/charts/docs
//   return (
//     <div className="container" id="wrapper">
//       <div className="container-fluid" id="current-weather">
//         <div className="row">

//         {/* <ReactFrappeChart
//       type="bar"
//       colors={["#21ba45"]}
//       axisOptions={{ xAxisMode: "tick", yAxisMode: "tick", xIsSeries: 1 }}
//       height={250}
//       data={{
//         labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
//         datasets: [{ values: [18, 40, 30, 35, 8, 52, 17, 4] }],
//       }}
//       /> */}
//       <div className="donut">
//         <Chart options={this.state.options} series={this.state.series} type="donut" width="380" />
//       </div>
//         </div>
//       </div>
//     </div>
//   );
// };

class Pool extends Component {


  constructor(props) {
    super(props);

    this.state = {
      graph: {},
      loading: true
    }    

    // this.state = {
    //   options: {},
    //   series: [44, 55, 41, 17, 15],
    //   labels: ['A', 'B', 'C', 'D', 'E']
    // }
    // let temp_reading = [
    //   {
    //     "name": "Kujopolis",
    //     "data": [28,29,33,36,32,32,28,29,33,36,32,32,28,29,33,36,32,32,29,33,36,32,32,33]
    //   },
    //   {
    //     "name": "dave",
    //     "data": [23,25,30,33,32,32,28,26,30,28,30,38,26,27,28,29,29,26,26,27,28,29]
    //   }
    // ];
    // let temp_axis = [1,2,3,4,5,6,7,8,9,10,11,12,1,2,3,4,5,6,7,8,9,10,11,12];
    // let temp_min = 10;
    // let temp_max = 40;


    // console.log("pool constructor done");
    // this.state =  temp;
  };

  componentDidMount() {
    const controller = new AbortController();

    const fetchData = async () => {
      const id = "shrink"; // "shrink";
      const APIURL = `https://1qqnpu5gj0.execute-api.us-east-2.amazonaws.com/Prod/getpool/${id}`;
      try {

        this.setState({loading: true});
        const currentPool = await axios(
          APIURL,
          {
            responseType: 'json',
            signal: controller.signal,
          }
        );

        console.log( currentPool );

        let {temp_reading, temp_axis, temp_min, temp_max} = currentPool.data.tempReadings;

        let temp = 
        {
          "series": temp_reading,
          "options": {
            "chart": {
              "height": 350,
              "type": "line",
              "dropShadow": {
                "enabled": true,
                "color": "#fff",
                "top": 18,
                "left": 7,
                "blur": 10,
                "opacity": 0.2
              },
              "toolbar": {
                "show": false
              }
            },
            "colors": [
              "#8CD0ED",
              "#45BBED"
            ],
            "dataLabels": {
              "color": "#fff",
              "enabled": true
            },
            "stroke": {
              "curve": "smooth"
            },
            "title": {
              "color": "#fff",          
              "text": "Average Temperature",
              "align": "center"
            },
            "grid": {
              "borderColor": "#689BB0",
              "row": {
                "colors": [
                  "#2E7FA1",
                  "transparent"
                ],
                "opacity": 0.5
              }
            },
            "markers": {
              "size": 1
            },
            "xaxis": {
              "categories": temp_axis,
              "title": {
                "color": "#fff",            
                "text": "Hour"
              }
            },
            "yaxis": {
              "title": {
                "color": "#fff",            
                "text": "Temperature"
              },
              "min": temp_min,
              "max": temp_max
            },
            "legend": {
              "color": "#fff",          
              "position": "top",
              "horizontalAlign": "right",
              "floating": true,
              "offsetY": -25,
              "offsetX": -5
            }
          }
        };
    
        this.setState({graph: temp});
        // setWeatherReport(currentWeather);

      } catch (err) {
        console.log("catch");
        if (axios.isCancel(err)) {
          return console.info(err);
        }

        console.error(err);
      } finally {
        this.setState({loading: false});
      }

    };
  
    fetchData().catch(console.error);

    return () => {
      console.log("Pool - Calling cleanup");

      // here we cancel preveous http request that did not complete yet
      controller.abort()
    };         
  }  


  render() {

    if (this.state.loading) {

      return( <div>Loading</div>);

    } else {

    return (
      <div className="container" id="wrapper-pool">
        <div className="container-fluid" id="current-pool">
          <div className="row">      
            <div className="thechart">
              <Chart options={this.state.graph.options} series={this.state.graph.series} type="line" width="680" />
            </div>
          </div>
        </div>
      </div>
    );
    }
  }
}
 
export default Pool;