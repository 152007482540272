// import logo from './logo.svg';
import './App.css';

import Weather from "./components/Weather";
import Pool from "./components/Pool";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>ShrinkMeter</p>
      </header>
      <Weather />
      <Pool />
    </div>
  );
}

export default App;
